import React from "react"
import { graphql } from "gatsby"
import * as styles from "./alert.module.scss"
import { ParsedText } from "components/content/ParsedText"

interface AlertProps {
  svg_icon?: string
  title: string
  url: string
  background_color?: string
}

export const AlertBanner = ({
  title,
  url,
  svg_icon = "",
  background_color = "",
}: AlertProps) => {
  return (
    <div className={styles.emergencyTicker}>
      <div className={styles.marquee}>
        {svg_icon && <ParsedText content={svg_icon} />}
        <a href={url}>{title}</a>
      </div>
    </div>
  )
}

export const strapiQuery = graphql`
  fragment StrapiAlertBannerFragment on STRAPI_ALERT {
    alert_banner {
      alert_text
      background_color
      id
      link
      svg_icon
    }
    enable_alert_banner
    id
  }
`
