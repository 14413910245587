import React from "react"
import { graphql, PageProps, useStaticQuery } from "gatsby"
import { SEO } from "components/seo/seo"
import { AlertBanner } from "components/alert-banner/AlertBanner"
import { HeroBanner } from "components/hero/HeroBanner"
import { GlobalFooter } from "components/global-footer/GlobalFooter"
import { useLogos } from "hooks/queries/settings/useLogos"
import { ContentBlocks } from "components/content-blocks/ContentBlocks"
import { Layout } from "components/layout"
import { StubBanner } from "components/form-stub/StubBanner"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { TabbedHeroBanner } from "components/hero/TabbedHeroBanner"
import { EMPTY_PAGE } from "assets/helper/EmptyPage"
import type { Component } from "components/hero/_hooks/useHeroComponents"
import type { HeroTab } from "components/hero/hero-tab"

type PageContext = PageProps["pageContext"] & {
  breadcrumb: {
    crumbs: Record<string, string>[]
    location: string
  }
  contentUrlPathCodex: Record<string, string>
  linkedPagesUrlPathCodex: Record<string, string>
}

interface IndexPageProps extends PageProps {
  pageContext: PageContext
}

const IndexPage: React.FC<IndexPageProps> = ({ location, pageContext }) => {
  const { pathname, hash } = location
  const { contentUrlPathCodex, linkedPagesUrlPathCodex } = pageContext

  const { isAlertEnabled, alertBanner, globalFormStubBanner } =
    useGeneralSettings()

  const { headerLogo, faviconLogo } = useLogos()

  const data = useStaticQuery(getHomePage)
  const [homepage] = data.allStrapiPage.nodes

  const {
    content,
    hero,
    hero_banner_components,
    global_footer,
    alternating_content,
    meta_data,
  } = homepage ?? EMPTY_PAGE

  const blockAfterFormStubHasHeading = content[0]?.heading ? true : false
  const hasAlternatingContent = alternating_content

  const isTabbedHero: boolean = hero_banner_components
    ? hero_banner_components.some(
        (heroComponent: Component | HeroTab) =>
          heroComponent.strapi_component === "banners.hero-tab"
      )
    : false

  return (
    <Layout
      contentUrlPathCodex={contentUrlPathCodex}
      location="/"
      pathName={pathname}
      hash={hash}
      linkedPagesUrlPathCodex={linkedPagesUrlPathCodex}
    >
      <SEO
        title={meta_data?.meta_title}
        description={meta_data?.meta_description}
        canonical={meta_data?.canonical_link}
        image={meta_data?.rich_data_image}
        fallbackImage={headerLogo}
      />
      {isAlertEnabled && alertBanner && (
        <AlertBanner
          title={alertBanner.alert_text}
          url={alertBanner.link}
          svg_icon={alertBanner.svg_icon}
        />
      )}

      {hero && !isTabbedHero && (
        <HeroBanner banner={hero} components={hero_banner_components} />
      )}

      {hero && isTabbedHero && (
        <TabbedHeroBanner banner={hero} components={hero_banner_components} />
      )}

      {globalFormStubBanner && Object.keys(globalFormStubBanner).length > 0 && (
        <StubBanner
          formStub={globalFormStubBanner}
          nextBlockHasHeading={blockAfterFormStubHasHeading}
        />
      )}

      {content?.length > 0 && (
        <ContentBlocks
          strapiContent={content}
          hasAlternatingContentBlocks={hasAlternatingContent}
        />
      )}

      {global_footer?.content?.length > 0 && (
        <GlobalFooter content={global_footer.content} />
      )}
    </Layout>
  )
}

const getHomePage = graphql`
  query Home {
    allStrapiPage(filter: { slug: { eq: "/" } }) {
      nodes {
        title
        slug
        alternating_content
        meta_data {
          meta_title
          meta_description
          rich_data_link
          canonical_link
          rich_data_image {
            localFile {
              publicURL
            }
          }
        }
        hero {
          ...StrapiHeroBannerFragment
        }
        hero_banner_components {
          ...StrapiHighlightsGroupFragment
          ...StrapiTabbedHeroBannerFragment
          ...StrapiVideoBackgroundFragment
        }
        content {
          ...StrapiContentBlockFragment
          ...StrapiCouponBannerFragment
          ...StrapiFaqGroupBannerFragment
          ...StrapiFormFragment
          ...StrapiHighlightsGroupFragment
          ...StrapiMultiContentBlocksFragment
          ...StrapiHtmlEmbedFragment
          ...StrapiNavigationTilesFragment
          ...StrapiServiceComparisonFragment
          ...StrapiServiceFragment
          ...StrapiTabbedContentBannerFragment
        }
        global_footer {
          ...StrapiGlobalFooterFragment
        }
      }
    }
  }
`

export default IndexPage
