import React, { useContext } from "react"
import { FormFieldsContext } from "contexts/FormFieldsContext"
import { AnchorLink } from "components/anchor-link/AnchorLink"
import type { Fields } from "components/forms/formBuilder-types"
import { ButtonElements } from "_types/Button"
import { useLayout } from "contexts/components/LayoutContext"
import { getRelativePathFromCodex } from "components/button/utils/getRelativePathFromCodex"
import "./form-stub.module.scss"
import { getPathWithForwardSlash } from "components/button/utils/getPathWithForwardSlash"

interface FormStubProps {
  button: ButtonElements
  field: Fields
}

export const FormStub = ({ button, field }: FormStubProps) => {
  const { slug: layoutSlug, linkedPagesUrlPathCodex } = useLayout()

  const { form_field, form_field_type, label } = field

  const {
    anchor_reference,
    custom_anchor_reference,
    associated_page,
    button_text,
    migrated_associated_page,
  } = button

  const fieldType = form_field_type?.toLowerCase()

  const pageSlug = associated_page?.slug || migrated_associated_page || null
  const hasPageSlug = !!pageSlug

  const relativePath = getRelativePathFromCodex({
    codex: linkedPagesUrlPathCodex,
    path: pageSlug,
    codexKeyStartsWithForwardSlash: false,
  })

  const slug = hasPageSlug ? relativePath : getPathWithForwardSlash(layoutSlug)

  const anchorReference =
    custom_anchor_reference || anchor_reference || "form-banner"

  const { setEmailAddress } = useContext(FormFieldsContext)
  const setFields = (e: { target: HTMLInputElement }) => {
    const { value } = e?.target
    setEmailAddress(value)
  }

  return (
    <div className="form-stub">
      <div className="form-stub__input">
        {label && <label htmlFor={form_field ?? undefined}>{label}</label>}

        <input
          name={form_field ?? undefined}
          placeholder={
            fieldType === "email" ? "email@example.com" : "John Smith"
          }
          onBlur={setFields}
          type={fieldType}
        />
      </div>
      {slug && (
        <AnchorLink
          children={button_text}
          to={`${slug}#${anchorReference}`}
          className="button--solid-accent form-stub__button"
        />
      )}
    </div>
  )
}
