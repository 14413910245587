import React from "react"
import { graphql } from "gatsby"
import { BackgroundVideo } from "components/background-video"
import { BackgroundVideoPlayButton } from "components/background-video/BackgroundVideoPlayButton"
import { HeroContent } from "./HeroContent"
import { HeroHighlights } from "components/highlights/Hero/HeroHighlights"
import { useColorAreaSettings } from "hooks/useColorAreaSettings"
import { useHeroComponents } from "./_hooks/useHeroComponents"
import { Image } from "components/image/Image"
import type { Banner } from "./HeroBanner-types"
import type { Component } from "./_hooks/useHeroComponents"
import type { SingleHighlight } from "components/highlights/highlights-types"
import "./hero.module.scss"
import "./hero-background-video.module.scss"

interface HeroProps {
  banner: Banner
  components?: Component[]
}

export const HeroBanner = ({ banner, components }: HeroProps) => {
  const {
    background_image,
    heading,
    hero_buttons,
    secondary_image,
    sub_heading_rich,
    video_background_url,
  } = banner

  const {
    hasHighlights,
    highlights,
    hasVideoBackgroundComponent,
    videoBackground,
  } = useHeroComponents(components)

  const showPlayButton =
    (hasVideoBackgroundComponent && videoBackground?.video_upload?.url) ||
    !!video_background_url

  const { imageBannerColorOverlay } = useColorAreaSettings()

  const bannerColorOverride = imageBannerColorOverlay
    ? `${imageBannerColorOverlay}--backgroundColor`
    : ``

  const contentClassName = secondary_image
    ? "fr-hero__banner-content-with-image"
    : "fr-hero__banner-content"

  const overrideBackground = imageBannerColorOverlay
    ? `${imageBannerColorOverlay}--backgroundOverlayBefore`
    : ""

  return (
    <section className={`fr-hero ${bannerColorOverride} ${overrideBackground}`}>
      {background_image && (
        <Image
          alt={background_image.alternativeText || ""}
          className="fr-hero__background-image"
          image={background_image}
          loading="eager"
          fetchPriority="high"
        />
      )}
      <BackgroundVideo
        componentName="fr-hero"
        hasBackgroundImage={!!background_image?.localFile?.publicURL}
        url={
          hasVideoBackgroundComponent
            ? videoBackground?.video_upload?.url
            : video_background_url
        }
      >
        <div className={`${contentClassName} fr-container`}>
          <HeroContent
            baseClassName={contentClassName}
            buttons={hero_buttons}
            heading={heading}
            secondaryImage={secondary_image}
            subHeading={sub_heading_rich?.data?.sub_heading_rich}
          >
            {hasHighlights && (
              <div className="hero-banner-content__highlights">
                <HeroHighlights highlights={highlights as SingleHighlight[]} />
              </div>
            )}

            {showPlayButton && <BackgroundVideoPlayButton />}
          </HeroContent>
        </div>
      </BackgroundVideo>
    </section>
  )
}

export const strapiQuery = graphql`
  fragment StrapiHeroBannerFragment on STRAPI__COMPONENT_BANNERS_HERO_BANNER {
    id
    heading
    sub_heading_rich {
      data {
        sub_heading_rich
      }
    }
    tagline
    hero_buttons {
      ...StrapiButtonFragment
    }
    background_image {
      ...StrapiBaseImageFragment
      localFile {
        publicURL
        childImageSharp {
          gatsbyImageData(
            avifOptions: { lossless: false, speed: 100, quality: 40 }
            webpOptions: { quality: 50 }
            formats: [AUTO, AVIF, WEBP]
            placeholder: BLURRED
            breakpoints: [1024, 1366, 1920, 2560]
          )
        }
      }
    }
    video_background_url
    secondary_image {
      ...StrapiBaseImageFragment
      localFile {
        publicURL
        childImageSharp {
          gatsbyImageData(
            avifOptions: { lossless: true, quality: 70, speed: 100 }
            webpOptions: { quality: 90 }
            formats: [AUTO, AVIF, WEBP]
            placeholder: NONE
            layout: FIXED
          )
        }
      }
    }
  }
`
