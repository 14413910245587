import React from "react"
import { graphql } from "gatsby"
import { FormStub } from "./FormStub"
import type { StrapiFormStubBannerFormatted } from "./formStubBanner-types"
import "./form-stub.module.scss"

interface StubProps {
  formStub: StrapiFormStubBannerFormatted
  nextBlockHasHeading: boolean
}

export const StubBanner = ({ formStub, nextBlockHasHeading }: StubProps) => {
  const { heading, button, fields } = formStub
  const marginBottomModifier = nextBlockHasHeading
    ? "form-stub-banner--large-margin-bottom"
    : ""
  return (
    <section className={`form-stub-banner ${marginBottomModifier}`}>
      <div className="fr-container">
        <h3 className="form-stub-banner__heading">{heading}</h3>
        {fields.length > 0 && !!button && (
          <FormStub button={button} field={fields[0]} />
        )}
      </div>
    </section>
  )
}

export const strapiQuery = graphql`
  fragment StrapiFormStubBannerFragment on STRAPI_GLOBAL_COMPONENT {
    id
    global_form_stub_banner {
      id
      heading
      sub_heading
      button {
        ...StrapiButtonFragment
      }
      form_fields {
        id
        custom_place_holder
        form_field
        form_field_type
        hidden_field_value
        label
        options
        required
      }
      # icon
      # svg_icon
    }
  }
`
